<template>
    <div id="query-result">
        <a-spin :tip="diffLoadingTip" :spinning="diffLoading">
            <div class="cyfx">
                <p>Job id: {{ task.task_id }}</p>
                <a-button type="primary" class="baseBtn" style="border-radius: 44px !important;width: 107px;"
                          :disabled="operaDisabled" :loading="enrichmentAnalysisLoading" size="small"
                          @click="enrichmentAnalysis()">
                    <span style="font-size: 16px;">富集分析</span>
                </a-button>
            </div>

            <div class="download">
                <a-tag color="orange" @click="downloadFile(csvUrl)">
                    <a-icon type="cloud-download"/>
                  download degs csv
                </a-tag>
                <a-tag color="blue" @click="downloadFile(metaUrl)">
                    <a-icon type="cloud-download"/>
                  download meta csv
                </a-tag>
            </div>

            <a-table :columns="columns" :data-source="csvData" :scroll="{x:true}" rowKey="__EMPTY"
                     :pagination="pagination" @change="sampleTableChange">
                <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                     style="padding: 0.5rem;">
                    <a-input v-ant-ref="c => (searchInput = c)" :placeholder="`Search ${column.dataIndex}`"
                             :value="selectedKeys[0]"
                             size="small" style="width: 188px; margin-bottom: 8px; display: block;"
                             @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                             @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"/>
                    <a-button type="primary" icon="search" size="small" style="width: 90px; margin-right: 8px"
                              @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)">Search
                    </a-button>
                    <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">Reset</a-button>
                </div>
                <a-icon slot="filterIcon" slot-scope="filtered" type="search"
                        :style="{ color: filtered ? '#108ee9' : undefined }"/>
                <template slot="customRender" slot-scope="text, record, index, column">
                    <a v-if="searchText && searchedColumn === column.dataIndex" target="_blank"
                       :href="'https://useast.ensembl.org/Bos_taurus/Gene/Summary?db=core;g=' + text">
                        <template
                                v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
                            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">
                                {{ fragment }}
                            </mark>
                            <template v-else>{{ fragment }}</template>
                        </template>
                    </a>
                    <template v-else><a :href="'https://useast.ensembl.org/Bos_taurus/Gene/Summary?db=core;g=' + text"
                                        target="_blank">{{ text }}</a></template>
                </template>
                <!-- <template slot="pvalue" slot-scope="number, record">
                  <editable-cell-number
                    :number="number"
                    :e="true"
                    @change="onCellChange(record, 'pvalue', $event)"
                  />
                </template>
                <template slot="log2FoldChange" slot-scope="number, record">
                  <editable-cell-number
                    :number="number"
                    @change="onCellChange(record, 'log2FoldChange', $event)"
                  />
                </template> -->
            </a-table>

            <div class="conditions">
                <span>当前筛选条件</span>
                <div class="con">
                    <a-form layout="inline">
                        <a-form-item label="统计显著性" style="position: relative;left: 5%;">
                            <a-input-group compact>
                                <a-select size="large" v-model="filtForm.remarkable" @change="remarkableChange">
                                    <a-select-option value="pvalue">pvalue</a-select-option>
                                    <a-select-option value="padj">padj</a-select-option>
                                </a-select>
                                <a-input size="large" style="width: 50%" type="number"
                                         v-model="filtForm.remarkableValue"/>
                            </a-input-group>
                        </a-form-item>
                        <a-form-item label="|log2FoldChange|">
                            <a-input-number size="large" v-model="filtForm.absLog2FoldChange" :min="0" :max="10"/>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip placement="bottom">
                                <template slot="title">
                                    <span>将重新生成热图/火山图</span>
                                </template>
                                <a-button type="primary" @click="changeDiff" class="btn" :loading="diffLoading">
                                    结果过滤
                                </a-button>
                            </a-tooltip>
                        </a-form-item>
                    </a-form>
                </div>
            </div>

            <a-tabs default-active-key="1" type="card">
                <a-tab-pane key="1" tab="Volcano plot" force-render>
<!--                    <div class="chart-list">-->
                        <!--        <a-spin :tip="diffLoadingTip" :spinning="diffLoading">-->
                        <Chart echarts-id="volcano-plot" :option="volcanoPlotOption" :needJsonStr="true" @finished="echartsFinished"
                               height="70vh" width="100%"/>
                        <!--        </a-spin>-->
<!--                    </div>-->
                </a-tab-pane>
                <a-tab-pane key="2" tab="Heatmap" force-render>
                    <!--      <a-spin :tip="diffLoadingTip" :spinning="diffLoading">-->
                    <!--        <Chart echarts-id="heat-map-plot" :option="heatMapOption" height="60vh"  />-->
                    <div id="heatMap"></div>
                    <!--      </a-spin>-->
                </a-tab-pane>
            </a-tabs>
        </a-spin>
    </div>
</template>

<script>
// import axios from "axios";
// import * as XLSX from "xlsx/xlsx.mjs";
// /* 加载'fs'的readFile和writeFile支持 */
// import * as fs from "fs";
// XLSX.set_fs(fs);

import Chart from "@/components/Chart.vue";
import VolcanoPlotOption from "@/chartsOptions/volcanoPlotOption";

import EditableCellNumber from "@/components/EditableCellNumber"

import {parseCsv, downloadFile} from "@/utils/csv.js"
import {syncTaskListStorage} from '@/utils/index.js'
import {waitResponseTask} from '@/utils/storage.js'
import {submit_e4b_v2} from "@/request/task.js";
import {create_json_v2} from "@/request/api.js";
import Bus from '@/utils/bus';

let inchlib = null;
//火山图数据
let volcanoPlotData = {
    up:[],
    down:[],
    noDiff:[]
};

export default {
    name: "LayoutMicRNAQueryResult",
    components: {
        Chart, EditableCellNumber
    },
    data() {
        return {
            filterPopoverVisible: false,
            task: {},
            csvUrl: "",
            metaUrl: "",
            vstUrl: "",
            enrichmentAnalysisLoading: false, //富集分析按钮
            operaDisabled: true,
            filtForm: {
                remarkable: "pvalue",
                remarkableValue: 0.01,
                absLog2FoldChange: 2
            },
            /* 表格搜索 */
            searchText: '',
            searchInput: null,
            searchedColumn: '',
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showTotal(total) {
                    return `Total ${total} items`
                },
            },
            /* 表格搜索 End */
            diffLoading: true, //差异分析按钮点击后
            diffLoadingTip: "Loading...",
            csvData: [], //读取out.degs.csv后生成的表格和火山图数据
            vstData: [], //读取out.vst.csv生成差异后生成的热图数据
            columns: [],
            genesDiff: [], //差异分析得到的差异对象数组,包含geneId，log2FoldChange, pvalue
            geneIdDIff: [], //差异分析得到的差异基因字符串数组，用于生成热图
            //图表配置
            volcanoPlotOption: null,
            volcanoPlotUp: 0,//火山图up的数据量
            volcanoPlotDown: 0,//火山图down的数据量
            volcanoPlotNoDiff: 0,//火山图noDiff的数据量
            heatMapOption: null
        };
    },
    async created() {
        this.task = this.common.getLocalStorageItem("current_diff_micRNA_task");
        this.csvUrl = this.common.source_url + this.task.result.path + '/out.degs.csv';
        this.metaUrl = this.common.source_url + this.task.result.path + '/meta.csv';
        this.vstUrl = this.common.source_url + this.task.result.path + '/out.vst.csv';

        //加载画熱图所需js
        await this.common.loadJs("/js/jquery-2.0.3.js")
        await this.common.loadJs("/js/kinetic-v5.1.0.min.js")
        await this.common.loadJs("/js/inchlib-1.2.0.min.js")

        this.setColumns();
        //画火山图和热图
        this.drawPlot();
    },
    methods: {
        downloadFile,
        setColumns() {
            this.columns = [
                {
                    title: "geneID",
                    dataIndex: "__EMPTY",
                    key: "geneID",
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'customRender'
                    },
                    onFilter: (value, record) => record.__EMPTY.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            });
                        }
                    }
                },
                // {
                //     title: "gene_symbol",
                //     dataIndex: "SYMBOL",
                //     customRender: (text) => {
                //         if (text === "NA" || !text) {
                //             return ""
                //         }
                //         return text
                //     }
                // },
                // {
                //     title: "gene_name",
                //     dataIndex: "GENENAME",
                //     customRender: (text) => {
                //         if (text === "NA" || !text) {
                //             return ""
                //         }
                //         return text
                //     }
                // },
                {
                    title: "baseMean",
                    dataIndex: "baseMean",
                    customRender: (text, row, index) => text.toFixed(3)
                },
                {
                    title: "log2FoldChange",
                    dataIndex: "log2FoldChange",
                    defaultSortOrder: "descend",
                    sorter: (a, b) => a.log2FoldChange - b.log2FoldChange,
                    customRender: (text, row, index) => text.toFixed(3)
                    // scopedSlots: { customRender: 'log2FoldChange' },
                },
                {
                    title: "lfcSE",
                    dataIndex: "lfcSE",
                    customRender: (text, row, index) => text.toFixed(3)
                },
                {
                    title: "stat",
                    dataIndex: "stat",
                    customRender: (text, row, index) => text.toFixed(3)
                },
                {
                    title: "pvalue",
                    dataIndex: "pvalue",
                    defaultSortOrder: "descend",
                    sorter: (a, b) => a.pvalue - b.pvalue,
                    customRender: (text) => {
                        if (text === "NA" || !text) {
                            return ""
                        }
                        return text.toFixed(3)
                    }
                    // scopedSlots: { customRender: 'pvalue' },
                },
                {
                    title: "padj",
                    dataIndex: "padj",
                    defaultSortOrder: "descend",
                    sorter: (a, b) => a.padj - b.padj,
                    customRender: (text) => {
                        if (text === "NA" || !text) {
                            return "";
                        } else {
                            return text.toExponential(3);
                        }
                    }
                },
                {
                    title: "diff",
                    dataIndex: "diff",
                    customRender: (text, row, index) => {
                        //显著性一般会用qvalue或者pvalue作为筛选标准，小于某个值表示显著
                        const remarkable = row[this.filtForm.remarkable] < this.filtForm.remarkableValue;
                        //|log2FoldChange|是指绝对值 大于某个值
                        const absLog2FoldChange = Math.abs(row.log2FoldChange) > this.filtForm.absLog2FoldChange;
                        return remarkable && absLog2FoldChange ? "true" : "false";
                    }
                }
            ];

            //允许点击操作中的 富集分析 等按钮
            this.operaDisabled = false;
        },
        //筛选条件变化->差异分析按钮
        changeDiff() {
            this.filterPopoverVisible = false;
            this.diffLoading = true;
            //重置数据
            this.genesDiff = [];
            this.geneIdDIff = [];
            volcanoPlotData = {
                up:[],
                down:[],
                noDiff:[]
            };
            this.volcanoPlotUp = 0;
            this.volcanoPlotDown = 0;
            this.volcanoPlotNoDiff = 0;

            //for速度快
            for (let i = 0, c = this.csvData.length; i < c; i++) {
                const item = this.csvData[i];

                if(item[this.filtForm.remarkable] === "" /*|| item[this.filtForm.remarkable] === "NA" */ || item[this.filtForm.remarkable] === undefined || item[this.filtForm.remarkable] === null) return;

                //显著性一般会用qvalue或者pvalue作为筛选标准，小于某个值表示显著
                const remarkable = item[this.filtForm.remarkable] < this.filtForm.remarkableValue;
                //|log2FoldChange|是指绝对值 大于某个值
                const absLog2FoldChange = Math.abs(item.log2FoldChange) > this.filtForm.absLog2FoldChange;
                if (remarkable && absLog2FoldChange) {
                    //用于富集分析的值
                    this.genesDiff.push({
                        geneId: item.__EMPTY,
                        log2FoldChange: item.log2FoldChange,
                        pvalue: item.pvalue
                    })
                    //差异的基因ID数组
                    this.geneIdDIff.push(item.__EMPTY)
                }

                //火山图的数据
                // y轴小于 this.filtForm.remarkableValue的都是noDiff
                const iData = [item.log2FoldChange, -Math.log10(item[this.filtForm.remarkable]),item.SYMBOL,`-log10(${this.filtForm.remarkable})`];
                if(-Math.log10(item[this.filtForm.remarkable]) < -Math.log10(this.filtForm.remarkableValue)){
                    this.volcanoPlotNoDiff++;
                    volcanoPlotData.noDiff.push(iData)
                }else{
                    if(item.log2FoldChange > this.filtForm.absLog2FoldChange){
                        this.volcanoPlotUp++;
                        volcanoPlotData.up.push(iData)
                    }else if(item.log2FoldChange < -this.filtForm.absLog2FoldChange){
                        this.volcanoPlotDown++;
                        volcanoPlotData.down.push(iData)
                    }else{
                        this.volcanoPlotNoDiff++;
                        volcanoPlotData.noDiff.push(iData)
                    }
                }
            }

            this.diffLoadingTip = "正在进行图形渲染..."
            //重新生成火山图
            this.setVolcanoPlotOption();
            //重新生成热力图
            this.setHeatMapOption();
        },
        //echarts图渲染结束事件
        echartsFinished() {
            console.log("echarts图渲染结束事件")
            this.diffLoading = false;
        },
        //分页、排序、筛选变化时触发
        sampleTableChange(pagination, filters, sorter, c) {
            //如果是分页变化
            console.log(pagination, filters, sorter, c)
        },
        //画火山图和热图
        drawPlot() {
            /** 热图 */
            // parseCsv(this.vstUrl, (csv) => {
            //   this.vstData = csv;
            /** 火山图 */
            parseCsv(this.csvUrl, async (csv) => {
                this.csvData = csv;
                VolcanoPlotOption.title.subtext = this.task.meta.dispose + ' vs ' + this.task.meta.contrast;
                //进行差异分析
                this.changeDiff();
            })
            // })
        },
        //设置火山图数据
        setVolcanoPlotOption() {
            //设置y坐标name
            VolcanoPlotOption.yAxis.name = `-log10(${this.filtForm.remarkable})`;
            VolcanoPlotOption.legend.data = [
                `up:${this.volcanoPlotUp}`,
                `down:${this.volcanoPlotDown}`,
                `noDiff:${this.volcanoPlotNoDiff}`
            ];
            //指定数据
            VolcanoPlotOption.series[0].data = volcanoPlotData.up;
            VolcanoPlotOption.series[0].name = `up:${this.volcanoPlotUp}`;
            VolcanoPlotOption.series[1].data = volcanoPlotData.down;
            VolcanoPlotOption.series[1].name = `down:${this.volcanoPlotDown}`;
            VolcanoPlotOption.series[2].data = volcanoPlotData.noDiff;
            VolcanoPlotOption.series[2].name = `noDiff:${this.volcanoPlotNoDiff}`


            this.volcanoPlotOption = VolcanoPlotOption;
        },
        //设置热图数据
        async setHeatMapOption() {
            inchlib = new InCHlib({ //实例化 InCHlib
                target: "heatMap", //目标HTML元素的ID
                metadata: true, //打开元数据
                column_metadata: true, //打开列元数据
                max_height: this.common.vhToPxNum(70), //设置可视化的最大高度(以像素为单位)
                width: document.getElementById("heatMap").clientWidth, //设置可视化的宽度(以像素为单位)
                heatmap_colors: "RdYlBu", //设置集群数据的色标
                metadata_colors: "Reds", //设置元数据的色标
                //dendrogram: false, //设置为“false”，则仅显示热图。可以通过单击列标题对其进行排序
            });

            const res = await create_json_v2({
                "deseq2_id": this.task.task_id,
                "pvalue": this.filtForm.remarkableValue,
                "lfc": this.filtForm.absLog2FoldChange
            })

            const exprJson = JSON.parse(res.data);

            console.log(exprJson)

            inchlib.read_data(exprJson); //读取json数据
            inchlib.draw(); //绘制集群热图
        },
        //表格搜索
        handleSearch(selectedKeys, confirm, dataIndex) {
            confirm();
            this.searchText = selectedKeys[0];
            this.searchedColumn = dataIndex;
        },
        //重置搜索
        handleReset(clearFilters) {
            clearFilters();
            this.searchText = '';
        },
        //修改单元格的值 $event是修改后的值
        onCellChange(record, name, $event) {
            record[name] = parseFloat($event);
        },
        //筛选条件的键改变
        remarkableChange(value) {
            //火山图
            // this.setVolcanoPlotOption();
        },
        //富集分析
        enrichmentAnalysis() {
            this.enrichmentAnalysisLoading = true;

            let genesDiff = "";
            this.genesDiff.forEach(item => {
                genesDiff += `${item.geneId}\t${item.log2FoldChange}\t${item.pvalue}\n`;
            })

          submit_e4b_v2({
                email: "",
                genes: genesDiff
            }).then(res => {
                if (res.code !== 0) {
                    this.$notification.error({
                        message: '异步任务创建失败',
                        description: '富集分析任务创建失败，请重试！',
                    });
                    return;
                }

                const storageInfo = ["micRNA-富集分析", res.uid, {
                    dispose: this.task.meta.dispose, //处理组值
                    contrast: this.task.meta.contrast //对照组值
                }]

                syncTaskListStorage(...storageInfo);

                //设置需要轮询结果的id
                waitResponseTask(...storageInfo);

                this.$notification.success({
                    message: '异步任务已添加',
                    description: '富集分析任务已加入任务队列，请查看右下角Task',
                });

                Bus.$emit('pollingInterval');

                this.enrichmentAnalysisLoading = false;
            })
        }
    },
};
</script>

<style scoped lang="scss">
#query-result {
  background: #F9F9FB;
  padding: 1.4rem 8rem;
  position: relative;

  ::v-deep .ant-spin-container {
    padding: 2rem;
    background: #fff;
  }

  .cyfx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 1rem;

    p {
      font-size: 1rem;
      color: #333;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .download {
    display: flex;
    justify-content: end;
    padding: 40px 0;

    .ant-tag {
      cursor: pointer;
      height: 33px;
      line-height: 33px;
      font-size: 16px;
    }
  }

  .conditions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    height: 2rem;

    span {
      font-size: 1rem;
      font-weight: 600;
      color: #333333;
    }

    .con {
      .ant-form {
        font-size: 0.8rem;
        font-weight: 600 !important;
        color: #000000;
      }

      .btn {
        width: 107px;
        height: 40px;
        background: rgba(9, 127, 53, 0.12);
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        border: 1px solid #097F35;

        ::v-deep span {
          font-size: 16px;
          font-weight: 400;
          color: #097F35;
        }
      }
    }
  }
}
</style>
